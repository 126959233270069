.job-skeleton{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-left: 10px;
    /* display: flex; */
}
.job-skeleton div{
    margin: 10px;
}
.abt-skeleton span{
    margin-top:5px!important;
}
.skeleton{
    margin:15px;
}

.profile-skeleton{
    margin-top:25px!important;
    width: 100%;
}
.profile-skeleton span{
    float: left;
    margin-left:7px;
}
/* .rm span{
    background-color: brown;
} */
.css-1vmnjpn-skeletonStyles-Skeleton{
    background-image: linear-gradient( 90deg,rgb(230, 230, 230),#ededed,rgb(230, 230, 230) )!important;
}
.skeletonTab{
    flex-direction: column;
}

@media (max-width: 600px){
    .skeletonTab{
        display: flex;
        flex-direction: column;
        width: 80%;
    }
}
