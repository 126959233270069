.navbar{
    width:100%;
    height:7vh;
    background-color: #0b5dbd;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top:0;
    z-index: 999;
}
.links{
    display: flex;
    list-style-type: none;
    color:white
}
.linkNav{
    padding:10px
}
.logoMain{
    width: 150px;
    height:auto;
    /* position: absolute;
    left:46% */

}

.profile{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    align-items: center;
}
.profilePic{
    height:45px;
    width: 45px;
    border-radius: 50%;
    margin:5px;
    object-fit: cover;
    /* background-color: #ffff; */
}
.name{
    color: white;
    margin:5px;
    font-size: 14px;
    font-weight: bold;
}
.addJob{
    width: 75px;
    height:30px;
    /* border:2px solid white; */
    color: white;
    background-color: inherit;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navMenu{
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
    align-items: center;
    padding: 10px;
    width: 100px;
}
.add{
    width:25px;
    height: 25px;
    padding: 5px;
}
.logout{
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
}
.logout h3{
    font-size: 8px;
    display: inline-block;
    width: auto;
    color:white;
    color: #0b5dbd;
    transition: 0.3s;
}
.logout .add{
    transition: 0.3s;
}
.logout:hover{
    filter: none;
}
.logout .add:hover{
    filter: none;
}
.logout h3:hover{
    color: #c60000;
    font-size: 16px;
}
.nav-start{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 9999;
}
.logo-landing{
    height:60px;
    width:auto;
    margin:20px
}
.list-landing{
    display: flex;
    margin: 20px;
    list-style-type: none;
    font-size: 1.2rem;
    font-family: 'Commissioner', sans-serif;
    font-weight: bold;
    align-items: center;
    /* color: #ffff; */
}

.list-landing li{
    margin:10px;
    font-family: 'Montserrat';
}
.list-landing li a{
    color: #ffff;
	-webkit-text-fill-color: rgb(206, 207, 226);
	-webkit-text-fill-color: rgb(210, 211, 225);
}
.login-landing{
    background-color: #0b5dbd;
    width:150px;
    height:50px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:5px;
    transition: 0.2s ease-in-out;
}
.login-landing:hover{
    background-color: #1271dd;
}
.login-landing h4{
    color: #ffff;
    font-size: 1.2rem;
}

.login-landing div a h4{

    color: #ffff!important;
}
.login-landing img{
    width: 25px;
    height: auto;
    margin-left:10px
}

@media only screen and (max-width: 600px){
    .nav-start{

        flex-direction: column;
        padding-top:15px;
    }
    .nav-start .row{
        justify-content: space-between!important;
        width:100%;
        padding-top:0px!important;
        margin:0

    }
    .logo-landing{
        height:40px;
    }
    .list-landing{
        font-size: 1rem;
        margin:10px;
    }
    .list-landing li{
        margin:7px
    }
    .login-landing{
        width:150px;
        height:40px;
        margin-right: 10px;
        margin-left:10px
    }
    .login-landing h4{
        font-size: 1rem;
    }
    .login-landing img{
        width:20px
    }
}
