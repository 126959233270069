/* @keyframes GradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @-webkit-keyframes GradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  } */

  @-webkit-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @-moz-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
.wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(45deg,#F17C58, #E94584, #24AADB , #27DBB1,#FFDC18, #FF3706);
    background-size: 600% 100%;
    animation: gradient 16s linear infinite;
    animation-direction: alternate;
}

.hide-desktop{
    display: none!important;
}
@media only screen and (max-width: 600px) {
   .hide-mobile{
    display: none!important;
   }
   body{
       overflow: hidden;
   }
   .rchome{
      display: flex;
      flex-direction: column;
      height:93vh;
      justify-content: center;
      align-items: center;
   }
   .rchome a{
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .jobDetails{
       display:none
   }
   .container{
       width: 100%!important;
       margin-left:0!important;
   }
   .listContainer{
       width:90%!important;
       padding:5px!important
   }
   .listContainer h4{
       font-size: 14px;;
   }
   .jobTitle{
       font-size: 0.9rem!important;
       margin-top:30px!important;
       margin-bottom:15px;
   }
   .certHeading{
       font-size:0.8rem!important;
       color: rgb(44, 45, 48);
       padding-left:0px!important;
   }
   .certPara{
       padding-left:0px!important;
   }
   .ReactModal_Overlay{
       background-color: rgba(255, 255, 255, 0.487)!important;
   }
   .application{
    font-size: 11px!important;
    border-radius: 3px;
   }

   .application img{
       height:14px!important;
   }
   
   .jobRow img{
       width:35px;
       
   }
   .jobRow button{
       border: none;
       background-color: #ffff;
       /* box-shadow: 2px 3px 10px #d3d3d3; */
   }

   .jobAbout{
       margin:10px 10px;
       width:100%!important;
       margin-top: 10px!important;
   }
   .jobAbout .listRow{
      width:fit-content;
      justify-content: center!important;
      align-items: baseline!important;
      padding:0!important;
     
   }
   .listRow{
    height:auto!important;
   }
   .listRow-R .row{
       padding-top:1px!important;
       margin:1px!important;
   }
   .aboutJob{
       width:100%!important;
       height:100px!important;
       overflow: scroll!important;
       position: fixed!important;
       background-color: none;
       top:55px!important;
       left:0!important;
       right:0!important;
       z-index: 100;
   }
   .aboutJob h4{
       font-size: 0.8rem;
       padding:5px;
   }
   .aboutJob h5{
       font-size: 0.7rem;
       padding:5px;
   }
   .aboutJob p{
       font-size: 0.7rem!important;
       padding:0!important;
   }
   .scrollContainer{
       width:100%!important;
       height:93vh;
       border:none;
       margin-top:150px!important;
       /* z-index: -99; */
       left:0!important;
   }
   .profileView{
       display:none!important;
   }
   .profileList{
       margin-top:0!important;
       justify-content: flex-start!important;
       padding-left: 10px;
   }
   
   .mainContainer-R{
       margin-top: 50px;
       flex-direction: column;
   }
   .profileContainer{
       margin:20px!important;
       width:220px!important;
   }
   .login-P{
       width:320px!important;
   }
   .basic-info{
       width: 90%!important;
       margin-bottom: 80px!important;
   }
   .infoColumn{
       width:100%!important;
   }
   .infoColumn .row{
       padding-top:1px!important
   }
   .basic-info p{
       padding-left:0!important;
       margin-left: 5px;
   }
   .photoColumn{
       width:100%!important;
   }
   .photoColumn .row{
      width: fit-content!important;
   }
   .logoMain{
       width:120px!important;
       height:auto!important;
       margin-left:10px;
   }
   .profilePic{
       width:120px!important;
       height:120px!important;
       border:1px solid #d3d3d3;
   }
   .name{
    color: rgb(44, 45, 48)!important;
    font-weight: bold!important;
   }
   .profile .button{
       width:120px!important;
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .profile .button img{
       width:15px!important;
       height:auto;
       padding: 5px;
   }
   .profile .red{
       background-color: maroon!important;
   }
   .scrollContainer h3{
       font-size: 0.7rem!important;
   }
   .profileTab{
       /* height:100px!important;
       width:100%!important; */
       flex-direction: column;
       margin:0
   }
   .profileTab .row p{
       font-size: 0.6rem!important;
   }
   /* .profileTab .row{
       padding: 5px!important;
       width: fit-content!important;
   } */
   .dp{
       height:65px!important;
   }
   .startup{
       display: flex;
       flex-direction: column;
   }
   .startupTab{
       width:180px;
       height:200px;
       border-radius: 1px;
   }
   .startupTab img{
    width:18%;
    height:auto;

   }
   .startupTab h2{
       font-size: 16px;
       margin: 10px;
   }
   .rcSelect{
       width:130px;
       height:130px;
   }
   .rcSelect img{
    width:30px;
    height:auto
   }
   .rcSelect h3{
    font-size: 12px;
    font-weight: 700!important;
    font-family: 'Montserrat';
   }
   .picContainer{
       /* padding-left:5px!important;
       width: 30%!important; */
       width:90px!important;
       height:90px!important;
       margin:0px!important
   }
   .login{
       width:300px
   }
   .landing{
    background-image: url('../assets//images/mobilehd1.jpg');
    background-size: 100% auto;
   }
   .landing-main{

   }
   .landing-about{
       position: inherit;
       width:100%;
       padding:20px;
   }
   .landing-about h4{
       font-size: 1rem;
   }
   .landing-about h1{
       font-size: 1.5rem;
   }
   .create-landing h1{
       font-size: 1.5rem;
   }
   .create-landing h2{
    font-size: 1rem;
   }
   .about{
    background-image: url('../assets//images/mobilehd3.jpg');
    background-size: 100% auto;

   }
   .wrapper-bg-mobile{
    height: 100vh;
    width: 100vw;
    background-image: none!important;
    background: linear-gradient(0deg, #43C6AC,#0b5dbd, #191654)!important;
    /* background: -webkit-linear-gradient(270deg, #acb6e5, #0b5dbd)!important; */
    background: -webkit-linear-gradient(270deg, #A5CC82, #00467F)!important;

    /*  this color scheme is from - https://uigradients.com/#Wiretap  */
    background-size: 100% 100%!important;
    animation: GradientAnimation 2s ease infinite!important;
    -webkit-animation: GradientAnimation 2s ease infinite!important;
    
   }
   .containerLogin{
       width:88%;
   }
   .profileApp{
    position: static!important;
    width: 85%!important;
}
/* .profileApp .button{
    height:50px;
    margin:0
} */
.appDocs{
    position: static!important;
    width:100%!important;
    margin:0
}
.job-add .login-P{
  width: 95%!important;
}
.ReactModal__Content{
    width: auto!important;
    height: auto!important;
    overflow: scroll;
}

  }

  /* .view-profile{
      display: flex;
      flex-direction: column;

  } */