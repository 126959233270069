.appDocs {
    width: 70%;
    margin-right: 1%;
    min-height: 90vh;
    background-color: #ffff;
    position: fixed;
    /* float: right; */
    right: 5px;
    bottom: 0;
    top: 7vh;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis;
    border: 2px solid #ebebeb;
}

.profileApp {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    width: 23%;
    min-height: 60vh;
    position: fixed;
    top:7vh;
    left:0;
    /* height: 80px; */
    border: 1px solid #eeeeee;
    box-shadow: 2px 3px 7px #d3d3d3;
    background-color: white;
    margin: 5px;
    padding: 20px;
}
.profileApp .listHeading-R{
    width:95%;
    height:95%;
    justify-content: flex-start;
}
.profileApp .listHeading-R .listRow-Dp{
    justify-content: flex-start;
}
.profileApp .row{
    padding-top:0!important;
}
.profileApp h4{
    font-size: 1.1rem!important;
    font-weight: bold!important;
    margin-bottom: 10px;
}
.listRow-R{
    display:flex;
    flex-direction: column!important;
    align-items: flex-start!important;
}
.appDocs img{
   width:100%;
   height:auto;
}
.rowApp{
    display: flex;
    flex-direction: row;
    width: 70%;
    /* justify-content: center; */
    align-items: center;
}
.rowApp h3{
    font-size: 1rem;
    padding:5px;
}
.rowApp h4{
    font-size: 1.1rem!important;
    padding:5px;
}
.appDocs .listRow-R{
    height:fit-content!important;
    padding-top:50px!important;
}
.profile-Ap{
        /* width: 100px; */
        height: 150px;
        min-width: 150px;
        border-radius: 75px;
        border: 1px solid #d3d3d3;
        object-fit: cover;
}
.text-muted{
    color: rgb(109, 114, 121);
    margin:3px;
    font-family: 'Roboto Slab', serif
}
.react-pdf__Page__svg{
    margin:auto!important;
    max-height:150vh!important;
}
.react-pdf__Document{
    height: 100% !important;
}
.react-pdf__Page__svg svg{
    height: 100%!important;
    width: auto!important;
}
/* .pdf-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position:relative
} */



/* .react-pdf__Page__svg{
    width: 100%!important;
}
.react-pdf__Page__svg svg{
    width: 100%!important;
    height: auto!important;
} */

/* .react-pdf__Page__canvas{
    width: 100%!important;
    height: auto!important;
} */
/* .pdf-row{
    display: block;
    width:100%;
    height:auto!important
} */
.button-pdf{
    background-color: #0b5dbd;
    color: #ffff;
    padding:10px;
    /* width:150px; */
    height:90px;
    border:none;
    border-radius: 5px ;
    font-size: 1.2rem;
}

.cert-heading{
    /* background-color: #0b5dbd; */
    background-color: #008080;


    width:400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px 40px 0px;
    padding:10px
}
.cert-heading h3{
    font-size: 1.2rem;
    color: #ffff!important;
    font-weight: bold;
}

.button-pdf img{
    width:30px;
    height:30px
}
.previous{
    position: absolute;
    left:0;
    top:40%
}
.next{
    position: absolute;
    right:0;
    top:40%
}


.ReactModal__Content{
    width: 98vw;
    height:85vh;
    z-index: 9999;
    position: relative;
    /* overflow: hidden!important; */

}

.clode-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0b5dbd!important;
    padding:5px 10px;
    border-radius: 5px;
    position:absolute;
    top:0;
    right:0;
    margin:10px;
    border:none;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #ffff;
    font-size: 14px;
    z-index: 9999;

}

.button-resume{
    padding: 5px 20px;
    background-color: #008080;
    color: #ffff;
    font-family: 'Montserrat';
    font-size: 0.6rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border:none;
    border-radius: 3px;
    margin: 10px;
    min-width: 40%;
}
.button-resume img{
    width:15px;
    height:15px;
    margin:5px 8px;
}

@media only screen and (max-width: 600px){
    .cert-heading{
        width:95%;
        /* position: sticky!important;
        margin-top: 50px;
        top:0 */
    }
    .cert-heading h3{
        font-size: 1rem;
    }
    .pdf-container{
        width:100%
    }
    .button-pdf{
        width:35px;
        font-size: 0.6rem;
        font-weight: bold!important;
        opacity: 0.6;
    }
    .button-pdf img{
        width: 100% !important;;
        height: auto !important;
    }
    .react-pdf__Page__svg{
        max-width: 100vw!important;
    }
    .react-pdf__Page__svg svg{
        max-width: 100vw!important;
        height: auto!important;

    }
    .react-pdf__Page__textContent{
        max-width: 100vw!important;
        height: auto!important;
    }
    .clode-modal{
        opacity: 0.7;
    }
    .rowApp{
        width:95%;
    }
    .rowApp img{
        margin-bottom: 20px;
        border:3px solid #a5a5a5;
        border-radius: 10px;
    }
    .appDocs{
        margin-bottom: 90px!important;
    }
}