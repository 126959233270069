.profileContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 190px;
    min-height:300px;
    /* height: 80px; */
    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
    margin:5px;
    padding:20px;

}
.profileContainer p{
    /* margin: 5px; */
    font-size:0.7rem!important;
}
.listRow-R h4{
    font-size:0.8rem;
    margin-left:5px;
    font-weight: 700;
}
.row{
  padding-top:2px!important
}
.listRow-Dp{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:30px 10px;
}
.mainContainer-R{
    display: flex;
    align-items: center; 
    justify-content: center;
    /* flex-direction: column; */
    width: 100%;
    position: relative;
}
.listRow-R{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width:100%;
    margin:2px 5px;
}
.profile-R{
    /* width:100px; */

    height: 120px;
    width: 120px;
    border-radius: 60px;
    border:1px solid #d3d3d3;
    object-fit: cover;

}
.jobIcon-R{
    width:20px;
    height:auto;
    margin:5px;
    /* padding-right: 5px; */
}
.listHeading-R{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: fit-content;
    cursor: default;
}
.add-Img{
    width:80px;
    height:auto;
}
.add-H{
    font-size: 1rem;
    color:#bdbdbd!important;
    margin-top:5px
}
.add:hover{
    background-color: #0b5dbd;
    color: #ffff;
}
.remove{
    width:40px;
    height: 40px;
    background-color:maroon;
    border-radius: 2px;
    border:solid 1px;
    color: maroon;
    margin-top: 10px;
}
.remove:hover{
    background-color: crimson;
    color: #ffff;
}
.ReactModal__Content{
    transition: 0.2s ease;
}