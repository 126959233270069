@import url(https://fonts.googleapis.com/css2?family=Commissioner:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
*{
  margin:0;
  padding: 0;
}

body{
    width:100vw;
    background-color: #fafafa;

}
#root{
    width:100vw!important;
    height:100vh!important;
    background-color: #fafafa;

}
a{
    text-decoration: none;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Montserrat', sans-serif;
}
p{
    font-family: 'Roboto Slab', serif;
}
label{
    font-family: 'Montserrat', sans-serif;
}
@-webkit-keyframes fadeInAnimation { 
    0% { 
        opacity: 0.4; 
    } 
    100% { 
        opacity: 1; 
     } 
}
@keyframes fadeInAnimation { 
    0% { 
        opacity: 0.4; 
    } 
    100% { 
        opacity: 1; 
     } 
} 
.main{ 
    -webkit-animation: fadeInAnimation ease-in 0.5s; 
            animation: fadeInAnimation ease-in 0.5s; 
    -webkit-animation-iteration-count: 1; 
            animation-iteration-count: 1; 
    -webkit-animation-fill-mode: forwards; 
            animation-fill-mode: forwards; 
} 
  

.main{
    height: 93vh;
    /* padding-top: 7vh; */
    background-color: #fafafa;
    display: flex;
    width: 100%;
    justify-content: center;
    overflow-y: auto;
    flex-wrap: wrap;
}
/* * {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
  }
   */
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
      display: none;  
    }

  /* *::-webkit-scrollbar-track {
    background: rgb(0, 0, 73);
  }
  *::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 20px;
    border: 10px solid #d3d3d3;
  } */
p{
    color: rgb(51, 54, 59);
    font-weight: 500;
    font-size: 0.9rem !important;
}
h1,h2,h3,h4{
    color:black;
}
.startup{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top:6vh; */
    width: 100%;
    overflow-y: hidden;
    /* overflow-x: hidden!important; */
    /* padding:50px 0px 50px 0px;  */
    /* flex-wrap: wrap; */
}
.startupTab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:350px;
    background-color: #0b5dbd;
    width:300px;
    border:2px solid #0b5dbd;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: 30px;
    flex-wrap: wrap;
}
.startupTab img{
    width: 30%;
    height: auto;
}
.startupTab h2{
    color: white;
    margin: 20px;
}
.login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:470px;
    background-color: white;
    width:350px;
    border:2px solid white;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: auto;
    position: relative;
    /* overflow-y: hidden; */
}
.login h2{
    color: rgb(83, 87, 94)!important;
    font-size: 1.2rem;
}
.login span{
    padding-top: 7px;
}
.containerLogin{
    /* margin-top: 13%; */
    display: flex;
    flex-direction: column;
    width:80%;
    margin-left:auto;
    margin-right: auto;
    align-items: center;
}
.containerLogin button{
    width:100%;
    background-color: #0b5dbd;
    color:white;
    height:40px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
}
.containerLogin form{
    width:100%
}

.row h3{
    text-align: start;
    color: #7B8B8C;
    font-size: 12px;
    font-weight: 400;
}
.container{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    /* width:30%; */
    /* border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e0e0e0; */

    margin-left:auto;
    margin-right: auto;
    align-items: center;

}
.loader{
    position: absolute;
    bottom: 12px;

}
.jobAdd{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width:450px;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    margin:50px;
    border:2px solid white;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    /* margin-top: 30px; */
}
.containerForm{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    height:100%;
    padding: 30px;
}
.containerForm textarea{
    background-color: #e1e4ea;
    /* border-color: #e1e4ea;
    border-style: solid; */
    border:none;
    width: 100%;
    min-height:200px;
    margin: 0;
    padding: 0;
}
.containerForm button{
    width:100%;
    background-color: #0b5dbd;
    color:white;
    height:40px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    font-size: 12px;
    font-weight: bold;
}
.row input{
    background-color: #e1e4ea;
    /* border-color: #e1e4ea;
    border-style: solid; */
    border:none;
    width: 100%;
    height: 38px;
    margin: 0;
    padding: 5;
    outline: none;
}
.rchome{
    display: flex;
    flex-direction: row;
    width:70%;
    justify-content: center;
    align-items: center;
}
.rcSelect{
    display: flex;
    flex-direction: column;
    width:200px;
    height:300px;
    background-color: #ffff;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border:1px solid #ededed;
    box-shadow: 1px 3px 10px #d3d3d3;
    transition: 0.27s;
}
.rcSelect img{
    width:60px;
    height:auto
}
.rcSelect h3{
    color: #dddddd;
    font-size: 18px;
    padding:15px
}
.rcSelect:hover{
    background-color: #0b5dbd;
}
.listContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 600px;
    height: 80px;
    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
    margin:5px;
    padding:20px;

}


.title{
    font-size:13px;
    font-weight: 500;
}
.button{
    width:100%;
    background-color: #0b5dbd;
    font-family: 'Montserrat', sans-serif;

    color:white;
    height:35px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 3px;
}
.rowButton{
    display: flex;
    flex-direction: row;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    align-items: center;
    width:100%
}
.containerRows{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    background-color: white;
    /* margin:5px; */
}
.listRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width:100%
}
.row{
    display: flex;
    flex-direction: row;
    /* width:100%; */
    /* justify-content: center; */
    justify-content: flex-start!important;
}
.containerLogin .row{
    width: 100%!important;
}
.picContainer{
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px; */
    /* width:20%; */
    /* height:fit-content;
    margin-right: 10px; */
}
.listHeading{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.listHeading p{
    font-size: 12px;
    margin:5px;
}
.listHeading img{
    width:20px;
    height:auto;
    /* margin:5px */
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1; /* Firefox */
  }
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1; /* Firefox */
  }
/* .dp{
    width: auto!important;
    height: 70px!important;
    border-radius: 50%;
} */
.certView{
    width:90%!important;
    height:auto;
    margin: auto;
}
/* .ReactModal__Content--after-open{
    background-color: #f2f2f2!important;
} */
.certHeading{
    font-size: 1rem;
    padding:5px
}
/* .ReactModal__Content--after-open .certPara{
    font-size: 1.5!important;
    padding:5px
} */
.certContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:20px 5px 20px 5px;

    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
}
.certRow{
    display: flex;
    flex-direction: row;
    width:100%;
    /* justify-content: center; */
    align-items: center;
}
.certImg{
    display: flex;
    justify-content: center;
}
.certInfo{
    /* display: flex;
    justify-content: center; */
}
.profileContainer{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    /* border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e0e0e0; */

    margin-left:auto;
    margin-right: auto;
    align-items: center;
    background-color: #f2f2f2;
}
.css-2b097c-container{
    width: 85%!important;
    display: inline-block!important;
    /* flex-direction: row!important; */
}
.css-1hb7zxy-IndicatorsContainer{
    display: none!important;
}
.landing{
  height:100vh;
  width:100vw;
  display: flex;

  /* background-image: url('../assets//images/home.jpg')!important;
  background-size: auto 100%;
  background-color: #5c5c5c;
  background-color: #595959;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-position: center; */
}
.landing-main{
    height: 100vh;

    display: flex;
    width: 100%;
    justify-content: center;
    overflow-y: auto;
    flex-wrap: wrap;
    -webkit-animation: fadeInAnimation ease-in 0.4s;
            animation: fadeInAnimation ease-in 0.4s; 
    -webkit-animation-iteration-count: 1; 
            animation-iteration-count: 1; 
    -webkit-animation-fill-mode: forwards; 
            animation-fill-mode: forwards; 
    /* background-image: url('../assets/images/home.jpg'); */
    background-size:cover;
    /* background-color: #5c5c5c; */
    background-color: #595959;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-position: center;
}
.create-landing{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom:0;
    left:0;
    margin:30px;
}
.create-landing h1,h2,a{
    color: #ffff!important;
}
.create-landing h2,a{
    font-family: 'Montserrat', sans-serif;
    /* font-weight: 500; */
}
.create-btn{
    background-color: #0b5dbd;
    width:250px;
    height:50px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.create-btn h4{
    color: #ffff;
}
.about{
    height:100vh;
    width:100vw;
    background-image: url([object Module]);
    background-size: cover;
    /* background-color: #5c5c5c; */
    background-color: #595959;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-position: center;
    position: relative;
}

.landing-about{
    width:60%;
    padding:50px;
    margin-bottom: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    
}
.landing-contact{
    width:60%;
    padding:50px;
    margin-bottom: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.landing-contact h4{
    font-size: 1.6rem;
    color: rgb(37, 37, 41);
}
.landing-about h4{
    /* color: #ffff; */
    font-size: 1.6rem;
    /* color: rgb(37, 37, 41); */
    color: #ffff;

}
.landing-about h1{
    color: #ffff;
    font-size: 2.8rem;
}


.contact-us{
    height:100vh;
    width:100vw;
    /* background-color: black; */

    /* background-image: url('../assets/images/hd4.jpg'); */
    background-size:  cover;
    /* background-color: #5c5c5c; */
    background-color: #595959;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-position: center;
    position: relative;
}
.forgot-para{
    font-size:0.8rem!important;
    font-weight: bold;
    margin:0;
    padding:0;
    font-family: 'Montserrat'
}

@media only screen and  (min-width:1700px){
    .landing,.about,.contact-us{
        background-size: 100% auto!important;
    }
  }
/* @keyframes GradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @-webkit-keyframes GradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  } */

  @-webkit-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
.wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(45deg,#F17C58, #E94584, #24AADB , #27DBB1,#FFDC18, #FF3706);
    background-size: 600% 100%;
    -webkit-animation: gradient 16s linear infinite;
            animation: gradient 16s linear infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}

.hide-desktop{
    display: none!important;
}
@media only screen and (max-width: 600px) {
   .hide-mobile{
    display: none!important;
   }
   body{
       overflow: hidden;
   }
   .rchome{
      display: flex;
      flex-direction: column;
      height:93vh;
      justify-content: center;
      align-items: center;
   }
   .rchome a{
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .jobDetails{
       display:none
   }
   .container{
       width: 100%!important;
       margin-left:0!important;
   }
   .listContainer{
       width:90%!important;
       padding:5px!important
   }
   .listContainer h4{
       font-size: 14px;;
   }
   .jobTitle{
       font-size: 0.9rem!important;
       margin-top:30px!important;
       margin-bottom:15px;
   }
   .certHeading{
       font-size:0.8rem!important;
       color: rgb(44, 45, 48);
       padding-left:0px!important;
   }
   .certPara{
       padding-left:0px!important;
   }
   .ReactModal_Overlay{
       background-color: rgba(255, 255, 255, 0.487)!important;
   }
   .application{
    font-size: 11px!important;
    border-radius: 3px;
   }

   .application img{
       height:14px!important;
   }
   
   .jobRow img{
       width:35px;
       
   }
   .jobRow button{
       border: none;
       background-color: #ffff;
       /* box-shadow: 2px 3px 10px #d3d3d3; */
   }

   .jobAbout{
       margin:10px 10px;
       width:100%!important;
       margin-top: 10px!important;
   }
   .jobAbout .listRow{
      width:-webkit-fit-content;
      width:-moz-fit-content;
      width:fit-content;
      justify-content: center!important;
      align-items: baseline!important;
      padding:0!important;
     
   }
   .listRow{
    height:auto!important;
   }
   .listRow-R .row{
       padding-top:1px!important;
       margin:1px!important;
   }
   .aboutJob{
       width:100%!important;
       height:100px!important;
       overflow: scroll!important;
       position: fixed!important;
       background-color: none;
       top:55px!important;
       left:0!important;
       right:0!important;
       z-index: 100;
   }
   .aboutJob h4{
       font-size: 0.8rem;
       padding:5px;
   }
   .aboutJob h5{
       font-size: 0.7rem;
       padding:5px;
   }
   .aboutJob p{
       font-size: 0.7rem!important;
       padding:0!important;
   }
   .scrollContainer{
       width:100%!important;
       height:93vh;
       border:none;
       margin-top:150px!important;
       /* z-index: -99; */
       left:0!important;
   }
   .profileView{
       display:none!important;
   }
   .profileList{
       margin-top:0!important;
       justify-content: flex-start!important;
       padding-left: 10px;
   }
   
   .mainContainer-R{
       margin-top: 50px;
       flex-direction: column;
   }
   .profileContainer{
       margin:20px!important;
       width:220px!important;
   }
   .login-P{
       width:320px!important;
   }
   .basic-info{
       width: 90%!important;
       margin-bottom: 80px!important;
   }
   .infoColumn{
       width:100%!important;
   }
   .infoColumn .row{
       padding-top:1px!important
   }
   .basic-info p{
       padding-left:0!important;
       margin-left: 5px;
   }
   .photoColumn{
       width:100%!important;
   }
   .photoColumn .row{
      width: -webkit-fit-content!important;
      width: -moz-fit-content!important;
      width: fit-content!important;
   }
   .logoMain{
       width:120px!important;
       height:auto!important;
       margin-left:10px;
   }
   .profilePic{
       width:120px!important;
       height:120px!important;
       border:1px solid #d3d3d3;
   }
   .name{
    color: rgb(44, 45, 48)!important;
    font-weight: bold!important;
   }
   .profile .button{
       width:120px!important;
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .profile .button img{
       width:15px!important;
       height:auto;
       padding: 5px;
   }
   .profile .red{
       background-color: maroon!important;
   }
   .scrollContainer h3{
       font-size: 0.7rem!important;
   }
   .profileTab{
       /* height:100px!important;
       width:100%!important; */
       flex-direction: column;
       margin:0
   }
   .profileTab .row p{
       font-size: 0.6rem!important;
   }
   /* .profileTab .row{
       padding: 5px!important;
       width: fit-content!important;
   } */
   .dp{
       height:65px!important;
   }
   .startup{
       display: flex;
       flex-direction: column;
   }
   .startupTab{
       width:180px;
       height:200px;
       border-radius: 1px;
   }
   .startupTab img{
    width:18%;
    height:auto;

   }
   .startupTab h2{
       font-size: 16px;
       margin: 10px;
   }
   .rcSelect{
       width:130px;
       height:130px;
   }
   .rcSelect img{
    width:30px;
    height:auto
   }
   .rcSelect h3{
    font-size: 12px;
    font-weight: 700!important;
    font-family: 'Montserrat';
   }
   .picContainer{
       /* padding-left:5px!important;
       width: 30%!important; */
       width:90px!important;
       height:90px!important;
       margin:0px!important
   }
   .login{
       width:300px
   }
   .landing{
    background-image: url([object Module]);
    background-size: 100% auto;
   }
   .landing-main{

   }
   .landing-about{
       position: inherit;
       width:100%;
       padding:20px;
   }
   .landing-about h4{
       font-size: 1rem;
   }
   .landing-about h1{
       font-size: 1.5rem;
   }
   .create-landing h1{
       font-size: 1.5rem;
   }
   .create-landing h2{
    font-size: 1rem;
   }
   .about{
    background-image: url([object Module]);
    background-size: 100% auto;

   }
   .wrapper-bg-mobile{
    height: 100vh;
    width: 100vw;
    background-image: none!important;
    background: linear-gradient(0deg, #43C6AC,#0b5dbd, #191654)!important;
    /* background: -webkit-linear-gradient(270deg, #acb6e5, #0b5dbd)!important; */
    background: -webkit-linear-gradient(270deg, #A5CC82, #00467F)!important;

    /*  this color scheme is from - https://uigradients.com/#Wiretap  */
    background-size: 100% 100%!important;
    animation: GradientAnimation 2s ease infinite!important;
    -webkit-animation: GradientAnimation 2s ease infinite!important;
    
   }
   .containerLogin{
       width:88%;
   }
   .profileApp{
    position: static!important;
    width: 85%!important;
}
/* .profileApp .button{
    height:50px;
    margin:0
} */
.appDocs{
    position: static!important;
    width:100%!important;
    margin:0
}
.job-add .login-P{
  width: 95%!important;
}
.ReactModal__Content{
    width: auto!important;
    height: auto!important;
    overflow: scroll;
}

  }

  /* .view-profile{
      display: flex;
      flex-direction: column;

  } */
/* .App {
  text-align: center;
} */



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar{
    width:100%;
    height:7vh;
    background-color: #0b5dbd;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top:0;
    z-index: 999;
}
.links{
    display: flex;
    list-style-type: none;
    color:white
}
.linkNav{
    padding:10px
}
.logoMain{
    width: 150px;
    height:auto;
    /* position: absolute;
    left:46% */

}

.profile{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    align-items: center;
}
.profilePic{
    height:45px;
    width: 45px;
    border-radius: 50%;
    margin:5px;
    object-fit: cover;
    /* background-color: #ffff; */
}
.name{
    color: white;
    margin:5px;
    font-size: 14px;
    font-weight: bold;
}
.addJob{
    width: 75px;
    height:30px;
    /* border:2px solid white; */
    color: white;
    background-color: inherit;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navMenu{
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
    align-items: center;
    padding: 10px;
    width: 100px;
}
.add{
    width:25px;
    height: 25px;
    padding: 5px;
}
.logout{
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
}
.logout h3{
    font-size: 8px;
    display: inline-block;
    width: auto;
    color:white;
    color: #0b5dbd;
    transition: 0.3s;
}
.logout .add{
    transition: 0.3s;
}
.logout:hover{
    -webkit-filter: none;
            filter: none;
}
.logout .add:hover{
    -webkit-filter: none;
            filter: none;
}
.logout h3:hover{
    color: #c60000;
    font-size: 16px;
}
.nav-start{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 9999;
}
.logo-landing{
    height:60px;
    width:auto;
    margin:20px
}
.list-landing{
    display: flex;
    margin: 20px;
    list-style-type: none;
    font-size: 1.2rem;
    font-family: 'Commissioner', sans-serif;
    font-weight: bold;
    align-items: center;
    /* color: #ffff; */
}

.list-landing li{
    margin:10px;
    font-family: 'Montserrat';
}
.list-landing li a{
    color: #ffff;
	-webkit-text-fill-color: rgb(206, 207, 226);
	-webkit-text-fill-color: rgb(210, 211, 225);
}
.login-landing{
    background-color: #0b5dbd;
    width:150px;
    height:50px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:5px;
    transition: 0.2s ease-in-out;
}
.login-landing:hover{
    background-color: #1271dd;
}
.login-landing h4{
    color: #ffff;
    font-size: 1.2rem;
}

.login-landing div a h4{

    color: #ffff!important;
}
.login-landing img{
    width: 25px;
    height: auto;
    margin-left:10px
}

@media only screen and (max-width: 600px){
    .nav-start{

        flex-direction: column;
        padding-top:15px;
    }
    .nav-start .row{
        justify-content: space-between!important;
        width:100%;
        padding-top:0px!important;
        margin:0

    }
    .logo-landing{
        height:40px;
    }
    .list-landing{
        font-size: 1rem;
        margin:10px;
    }
    .list-landing li{
        margin:7px
    }
    .login-landing{
        width:150px;
        height:40px;
        margin-right: 10px;
        margin-left:10px
    }
    .login-landing h4{
        font-size: 1rem;
    }
    .login-landing img{
        width:20px
    }
}


.jobAdd{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width:450px;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    margin:50px;
    border:2px solid white;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    /* margin-top: 30px; */
}
.containerAdd{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    height:100%;
    padding: 30px;
}
.containerAdd textarea{
    background-color: #e1e4ea;
    /* border-color: #e1e4ea;
    border-style: solid; */
    border:none;
    width: 100%;
    min-height:200px;
    margin: 0;
    padding: 5px!important;
}
.containerAdd button{
    width:100%;
    background-color: #0b5dbd;
    color:white;
    height:40px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    font-size: 12px;
    font-weight: bold;
}

.row{
    padding-top:2px!important
}
.row input{
    padding:5px!important;
    height:35px!important
}
.jobAbt{
    width:95%!important;
    padding: 5%;
    background-color: #e1e4ea;
    min-height: 250px;
    border: none;
}
.css-1wy0on6{
    display:none!important
}



.mainContainer{
    display: inline-block;
    width: 100%;
    position: relative;
}
.container{
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-left: 10%;
    align-items: center;
    float: left;
}
.listContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 80px;
    border:1px solid #eeeeee;
    box-shadow: 2px 3px 7px #d3d3d3;
    background-color: white;
    margin:5px;
    padding:20px;
    transition: -webkit-transform .15s;
    transition: transform .15s;
    transition: transform .15s, -webkit-transform .15s;

}
.listContainer:hover{
    border:1px solid rgb(129, 129, 141);
    /* background-color: #f6f6f6; */
    -webkit-transform: scale(1.01);
            transform: scale(1.01); 
    /* transform: border 2px solid #919191 ease; */

 }
 
.jobDetails{
    width: 40%;
    margin-right:10%;
    min-height: 90vh;
    background-color: #ffff;
    position: fixed;
    /* float: right; */
    right: 5px;
    bottom: 0;
    top:7vh;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis; 
    border:2px solid #ebebeb

}
.jobContainer{
    width: 90%;
    height:90%;
    margin:auto;
}
.jobContainer p{
    overflow-wrap: break-word!important;
    padding:5px

}

.title{
    font-size:13px;
    font-weight: 500;
}
.application{
    width:200px;
    background-color: #008080;
    color: #ffff;
    height:40px;
    padding: 0;
    /* margin-left:5px; */
    border:none !important;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    /* position: absolute; */
    top:0;
    right:0;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #8f8d9d;
    /* margin-left: 0; */
    transition: 0.2s;
    /* margin:5%; */
    /* margin-top:15px; */
}
.application img{
    height: 12px;
    width: auto;
    padding-left: 15px;
}
.application:hover{
    background-color: #009999!important;
}
.ll{
    position: relative;
    height: 100%;
    width:100%;
}
.rowButton{
    display: flex;
    flex-direction: row;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    align-items: center;
    width:100%
}
.containerRows{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    background-color: white;
    /* margin:5px; */
}

.listRow-J{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width:100%
}
.row{
    display: flex;
    flex-direction: row;
    /* width:100%; */
    /* justify-content: center; */
    align-items: center;
}
.picContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 5px; */
    /* width:20%; */
    /* height:fit-content; */
    margin-right: 10px;
}
.listHeading{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: default;
}
.listHeading h3{
    text-align: left;
    width: 100%;
    font-size: .8rem;
    font-weight: 700;
    color: rgb(51, 54, 59);
}
.listHeading p{
    font-size: 12px;
    margin:5px;
}
.listHeading img{
    width:auto;
    height:15px;
    margin:3px
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1; /* Firefox */
  }
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1; /* Firefox */
  }
.dpL{
    width: auto!important;
    height: 150px!important;
    border-radius: 50%;
}
.certView{
    width:90%!important;
    height:auto;
    margin: auto;
}
.ReactModal__Content--after-open{
    background-color: #ffff!important;
    width:90%;
    padding: 0!important;
    left:10px!important;
    right:10px!important;
    top:10vh!important;
    bottom:20px!important;
}
.ReactModal_Overlay{
    background-color: rgba(255, 255, 255, 0.487)!important;
}
.certHeading{
    font-size: 1rem;
    padding:5px;
    /* margin-top:35px; */
}
.jobTitle{
    margin-top:30px!important;
    padding:5px;
}

.certContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:20px 5px 20px 5px;

    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
}
.aboutContainer{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.jobRow{
    display: flex;
    flex-direction: row;
    width:100%;
    /* justify-content: center; */
    align-items: center;
    /* Renamed property in CSS3 draft spec */

}

.certImg{
    display: flex;
    justify-content: center;
}

.profileContainer{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    /* border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e0e0e0; */

    margin-left:auto;
    margin-right: auto;
    align-items: center;
    background-color: #f2f2f2;
}

.jobDetails::-webkit-scrollbar{
    display: none;
}

.viewApp{
    width:200px;
    background-color: #0b5dbd;
    color:white;
    height:35px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    font-size: 10px;
    font-weight: bold;
    position: -webkit-sticky;
    position: sticky;
    right:0
}

.active{
    color:#224368
}

.listRow-Cp{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.archive-btn{
    width:200px;
    height:40px;
    color: #ffff;
    border:none;
    /* margin-top:15px; */
    background-color: #224368;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    transition: 0.3s;

}
.archive-btn:hover{
    background-color: #2b5585;
}
.archive-btn .jobIcon{
    width:20px;
    height:auto;
    margin-left:8px;
}
.btn-row{
    display: flex;
    align-content: center;
    margin-top:15px;
}
.mainContainer{
    display: inline-block;
    width: 100%;
    position: relative;
}
.profileList{
    margin-top:6vh;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;


    overflow-y: scroll;
}
.profileTab{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    height: 240px;
    width:150px;
    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
    margin:5px;
    /* padding:20px; */
    transition: -webkit-transform .15s;
    transition: transform .15s;
    transition: transform .15s, -webkit-transform .15s;

}
.profileTab:hover{
    border:1px solid rgb(129, 129, 141);
    /* background-color: #f6f6f6; */
    -webkit-transform: scale(1.01);
            transform: scale(1.01); 
    /* transform: border 2px solid #919191 ease; */
 }
.profileTab h3{
  font-size: 1rem;
  margin-bottom: 5px;
  padding-left:5px!important;
}
.profileTab p{
    margin:5px!important;
    padding-left:3px!important;
}
.scrollContainer{
    width: 80%;
    height:100%;
    /* right:0; */
    left:23%;
    min-height: 90vh;

    position: fixed;
    /* float: right; */
    bottom: 0;
    top:0;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis; 

}
.profileView{
    width: 50%;
    right:0;
    min-height: 90vh;
    background-color: #ffff;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    /* float: right; */
    bottom: 0;
    top:0;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis; 
    border:2px solid #ebebeb

}

.profileView::-webkit-scrollbar{
    display: none;
}

.aboutJob{
    width: 20%;
    height: 100vh;
    background-color: #ffff;
    position: fixed;
    /* float: right; */
    bottom: 0;
    top:0;
    left: 0;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis; 
    border:2px solid #ebebeb;
    justify-content: center;
}
.jobContainer{
    width: 90%;
    height:90%;
    margin:auto;
}
.jobContainer p{
    overflow-wrap: break-word!important;
    padding:5px

}
.certPara{
    /* overflow-wrap: break-word;
    word-break: break-all; */

}
.apView{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* border:1px solid #0b5dbd; */
    background-color: rgb(240, 240, 240);
    margin-top:7vh;
    padding:20px
}
.title{
    font-size:13px;
    font-weight: 500;
}
.application{
    /* width:100%;
    background-color: #0b5dbd;
    color: #ffff;
    height:45px;
    padding: 0; */
    /* margin-left:5px; */
    /* border:none !important;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top:0;
    right:0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border:1px solid #8f8d9d; */
    /* margin-left: 0; */
}
.application img{
    height: 12px;
    width: auto;
    padding-left: 15px;
}
.application:hover{
    background-color: #0c6bd8;
}
.ll{
    position: relative;
    height: 100%;
    width:100%;
}
.rowButton{
    display: flex;
    flex-direction: row;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    align-items: center;
    width:100%
}
.containerRows{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    background-color: white;
    /* margin:5px; */
}

.listRow{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width:100%
}
.row{
    display: flex;
    flex-direction: row;
    /* width:100%; */
    /* justify-content: center; */
    align-items: center;
}
.picContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding-left: 25px; */
    width:90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    background-color: #d3d3d3;
    margin-bottom: 5px;
    /* height:fit-content; */

}
.listHeading{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: default;
}
.listHeading h3{
    text-align: left;
    width: 100%;
    font-size: .8rem;
    font-weight: 700;
    color: rgb(51, 54, 59);
}
.listHeading p{
    font-size: 12px;
    margin:5px;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1; /* Firefox */
  }

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1; /* Firefox */
  }
#dp{
    /* width: auto!important;
    height: 80px!important; */
    /* border-radius: 50%; */
    /* object-fit: cover; */
    width: 100%;
    height: auto;
}
.profileTab .row{
    width:100%;
    padding: 2px 10px;
    margin:5px;
}
.profileTab .row img{
    width: 14px;
    height: auto;
    padding-left:5px!important;
}
.profileTab .row p{
    margin:1px!important;
    font-size: 0.7rem!important;
}
.certView{
    width:90%!important;
    height:auto;
    margin: auto;
}

.certHeading{
    font-size: 1rem;
    padding:5px;
    /* margin-top:35px; */
}
.jobTitle{
    margin-top:50px
}

.certContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:20px 5px 20px 5px;

    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
}
.aboutContainer{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.jobRow{
    display: flex;
    flex-direction: row;
    width:100%;
    /* justify-content: center; */
    align-items: center;
    /* Renamed property in CSS3 draft spec */

}

.certImg{
    display: flex;
    justify-content: center;
}
.certInfo{
    /* display: flex;
    justify-content: center; */
}
.profileContainer{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    /* border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e0e0e0; */

    margin-left:auto;
    margin-right: auto;
    align-items: center;
    background-color: #f2f2f2;
}

.jobDetails::-webkit-scrollbar{
    display: none;
}

.viewApp{
    width:200px;
    background-color: #0b5dbd;
    color:white;
    height:35px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    font-size: 10px;
    font-weight: bold;
    position: -webkit-sticky;
    position: sticky;
    right:0
}

.jobAbout{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top:7vh;
}
.jobAbout p{
    padding:5px
}
.jobAbout .listRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px ;
} 
.apView .row{
    background-color: rgb(240, 240, 240);
}
.apView .listRow{
    background-color: rgb(240, 240, 240);

}
.appDocs {
    width: 70%;
    margin-right: 1%;
    min-height: 90vh;
    background-color: #ffff;
    position: fixed;
    /* float: right; */
    right: 5px;
    bottom: 0;
    top: 7vh;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis;
    border: 2px solid #ebebeb;
}

.profileApp {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    width: 23%;
    min-height: 60vh;
    position: fixed;
    top:7vh;
    left:0;
    /* height: 80px; */
    border: 1px solid #eeeeee;
    box-shadow: 2px 3px 7px #d3d3d3;
    background-color: white;
    margin: 5px;
    padding: 20px;
}
.profileApp .listHeading-R{
    width:95%;
    height:95%;
    justify-content: flex-start;
}
.profileApp .listHeading-R .listRow-Dp{
    justify-content: flex-start;
}
.profileApp .row{
    padding-top:0!important;
}
.profileApp h4{
    font-size: 1.1rem!important;
    font-weight: bold!important;
    margin-bottom: 10px;
}
.listRow-R{
    display:flex;
    flex-direction: column!important;
    align-items: flex-start!important;
}
.appDocs img{
   width:100%;
   height:auto;
}
.rowApp{
    display: flex;
    flex-direction: row;
    width: 70%;
    /* justify-content: center; */
    align-items: center;
}
.rowApp h3{
    font-size: 1rem;
    padding:5px;
}
.rowApp h4{
    font-size: 1.1rem!important;
    padding:5px;
}
.appDocs .listRow-R{
    height:-webkit-fit-content!important;
    height:-moz-fit-content!important;
    height:fit-content!important;
    padding-top:50px!important;
}
.profile-Ap{
        /* width: 100px; */
        height: 150px;
        min-width: 150px;
        border-radius: 75px;
        border: 1px solid #d3d3d3;
        object-fit: cover;
}
.text-muted{
    color: rgb(109, 114, 121);
    margin:3px;
    font-family: 'Roboto Slab', serif
}
.react-pdf__Page__svg{
    margin:auto!important;
    max-height:150vh!important;
}
.react-pdf__Document{
    height: 100% !important;
}
.react-pdf__Page__svg svg{
    height: 100%!important;
    width: auto!important;
}
/* .pdf-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position:relative
} */



/* .react-pdf__Page__svg{
    width: 100%!important;
}
.react-pdf__Page__svg svg{
    width: 100%!important;
    height: auto!important;
} */

/* .react-pdf__Page__canvas{
    width: 100%!important;
    height: auto!important;
} */
/* .pdf-row{
    display: block;
    width:100%;
    height:auto!important
} */
.button-pdf{
    background-color: #0b5dbd;
    color: #ffff;
    padding:10px;
    /* width:150px; */
    height:90px;
    border:none;
    border-radius: 5px ;
    font-size: 1.2rem;
}

.cert-heading{
    /* background-color: #0b5dbd; */
    background-color: #008080;


    width:400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px 40px 0px;
    padding:10px
}
.cert-heading h3{
    font-size: 1.2rem;
    color: #ffff!important;
    font-weight: bold;
}

.button-pdf img{
    width:30px;
    height:30px
}
.previous{
    position: absolute;
    left:0;
    top:40%
}
.next{
    position: absolute;
    right:0;
    top:40%
}


.ReactModal__Content{
    width: 98vw;
    height:85vh;
    z-index: 9999;
    position: relative;
    /* overflow: hidden!important; */

}

.clode-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0b5dbd!important;
    padding:5px 10px;
    border-radius: 5px;
    position:absolute;
    top:0;
    right:0;
    margin:10px;
    border:none;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #ffff;
    font-size: 14px;
    z-index: 9999;

}

.button-resume{
    padding: 5px 20px;
    background-color: #008080;
    color: #ffff;
    font-family: 'Montserrat';
    font-size: 0.6rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border:none;
    border-radius: 3px;
    margin: 10px;
    min-width: 40%;
}
.button-resume img{
    width:15px;
    height:15px;
    margin:5px 8px;
}

@media only screen and (max-width: 600px){
    .cert-heading{
        width:95%;
        /* position: sticky!important;
        margin-top: 50px;
        top:0 */
    }
    .cert-heading h3{
        font-size: 1rem;
    }
    .pdf-container{
        width:100%
    }
    .button-pdf{
        width:35px;
        font-size: 0.6rem;
        font-weight: bold!important;
        opacity: 0.6;
    }
    .button-pdf img{
        width: 100% !important;;
        height: auto !important;
    }
    .react-pdf__Page__svg{
        max-width: 100vw!important;
    }
    .react-pdf__Page__svg svg{
        max-width: 100vw!important;
        height: auto!important;

    }
    .react-pdf__Page__textContent{
        max-width: 100vw!important;
        height: auto!important;
    }
    .clode-modal{
        opacity: 0.7;
    }
    .rowApp{
        width:95%;
    }
    .rowApp img{
        margin-bottom: 20px;
        border:3px solid #a5a5a5;
        border-radius: 10px;
    }
    .appDocs{
        margin-bottom: 90px!important;
    }
}
.job-skeleton{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-left: 10px;
    /* display: flex; */
}
.job-skeleton div{
    margin: 10px;
}
.abt-skeleton span{
    margin-top:5px!important;
}
.skeleton{
    margin:15px;
}

.profile-skeleton{
    margin-top:25px!important;
    width: 100%;
}
.profile-skeleton span{
    float: left;
    margin-left:7px;
}
/* .rm span{
    background-color: brown;
} */
.css-1vmnjpn-skeletonStyles-Skeleton{
    background-image: linear-gradient( 90deg,rgb(230, 230, 230),#ededed,rgb(230, 230, 230) )!important;
}
.skeletonTab{
    flex-direction: column;
}

@media (max-width: 600px){
    .skeletonTab{
        display: flex;
        flex-direction: column;
        width: 80%;
    }
}

.profileContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 190px;
    min-height:300px;
    /* height: 80px; */
    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
    margin:5px;
    padding:20px;

}
.profileContainer p{
    /* margin: 5px; */
    font-size:0.7rem!important;
}
.listRow-R h4{
    font-size:0.8rem;
    margin-left:5px;
    font-weight: 700;
}
.row{
  padding-top:2px!important
}
.listRow-Dp{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:30px 10px;
}
.mainContainer-R{
    display: flex;
    align-items: center; 
    justify-content: center;
    /* flex-direction: column; */
    width: 100%;
    position: relative;
}
.listRow-R{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width:100%;
    margin:2px 5px;
}
.profile-R{
    /* width:100px; */

    height: 120px;
    width: 120px;
    border-radius: 60px;
    border:1px solid #d3d3d3;
    object-fit: cover;

}
.jobIcon-R{
    width:20px;
    height:auto;
    margin:5px;
    /* padding-right: 5px; */
}
.listHeading-R{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: default;
}
.add-Img{
    width:80px;
    height:auto;
}
.add-H{
    font-size: 1rem;
    color:#bdbdbd!important;
    margin-top:5px
}
.add:hover{
    background-color: #0b5dbd;
    color: #ffff;
}
.remove{
    width:40px;
    height: 40px;
    background-color:maroon;
    border-radius: 2px;
    border:solid 1px;
    color: maroon;
    margin-top: 10px;
}
.remove:hover{
    background-color: crimson;
    color: #ffff;
}
.ReactModal__Content{
    transition: 0.2s ease;
}
@-webkit-keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    60%{
        opacity: 0.6;
    }
    100% { 
        opacity: 1; 
     } 
} 
@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    60%{
        opacity: 0.6;
    }
    100% { 
        opacity: 1; 
     } 
} 
.login-P{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    background-color: white;
    /* width:400px; */
    border:2px solid white;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: auto;
    -webkit-animation: fadeInAnimation ease 0.8s;
            animation: fadeInAnimation ease 0.8s; 
    -webkit-animation-iteration-count: 1; 
            animation-iteration-count: 1; 
    -webkit-animation-fill-mode: forwards; 
            animation-fill-mode: forwards; 
}
.basic-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    background-color: white;
    width:600px;
    border:2px solid white;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: 30px auto;
}
.basic-info .row{
    width:90%;
    justify-content: flex-start;
}
.basic-info p{
    padding-left: 8px;
}
.basic-info h5{
   color: rgb(76, 76, 79)
}
.login-P form{
  width:90%
}
.row{
  margin:5px;
}
.row h4{
    margin:10px 0px;
}

.row .input-P{
    width:85%!important;
    padding-left:10px ;
    background-color: #e1e4ea;
}
.row label{
    font-size: 12px;
    width: 15%;
    color: rgb(39, 39, 48);
}
#about{
    min-height:200px;
    border:1px solid #949494;
    background-color: #ffff;
    width: 100%;
    padding: 10px;
}

.login-P ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000000;
    opacity: 1; /* Firefox */
}

.login-P ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000000;
    opacity: 1; /* Firefox */
}

.infoColumn{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.photoColumn{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;

}

.logo{
    width:50%;
    height: auto;
}

.file{
    color: #ffff;
    background-color: #ffff!important;
    width:90px!important;
}
.row-textarea{
    width:100%!important;
    display: flex!important;
    flex-direction: column!important;
}
.row-textarea .row{
    width: 100%!important;
}

.uploadPic{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:400px;
    background-color: white;
    width:300px;
    border:2px solid white;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: auto;
    -webkit-animation: fadeInAnimation ease 0.8s;
            animation: fadeInAnimation ease 0.8s; 
    -webkit-animation-iteration-count: 1; 
            animation-iteration-count: 1; 
    -webkit-animation-fill-mode: forwards; 
            animation-fill-mode: forwards; 
}

@-webkit-keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    60%{
        opacity: 0.6;
    }
    100% { 
        opacity: 1; 
     } 
} 
@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    60%{
        opacity: 0.6;
    }
    100% { 
        opacity: 1; 
     } 
} 
.login-P{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    padding:40px 0px;
    margin:40px 0px!important;
    background-color: white;
    width:600px;
    border:2px solid white;
    border-radius: 3px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: auto;
    -webkit-animation: fadeInAnimation ease 0.8s;
            animation: fadeInAnimation ease 0.8s; 
    -webkit-animation-iteration-count: 1; 
            animation-iteration-count: 1; 
    -webkit-animation-fill-mode: forwards; 
            animation-fill-mode: forwards; 
}
.login-Pic{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:300px;
    padding:30px 0px;
    background-color: white;
    width:250px;
    border:2px solid white;
    border-radius: 3px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: auto;
    -webkit-animation: fadeInAnimation ease 0.8s;
            animation: fadeInAnimation ease 0.8s; 
    -webkit-animation-iteration-count: 1; 
            animation-iteration-count: 1; 
    -webkit-animation-fill-mode: forwards; 
            animation-fill-mode: forwards; 
}
.login-P form{
  width:90%
}

.row-input{
    display: flex;
    width: 100%;
    flex-direction: row;
}
.row-Error{
 width:100%;
}
.row h4{
    margin:10px 0px;
}
.button{
    height:40px;
    margin-top:25px;
    font-size: 0.8rem;
}
.row-error{
    display: flex;
    justify-content: flex-end;
}
.error{
    color: crimson;
    font-size: 0.65rem!important;
    margin-right: 5px;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.row .input-P{
    width:75%!important;
    padding-left:10px ;
    border:1px solid #e1e4ea;
    background-color: #e1e4ea;
}
.row label{
    font-size: 12px;
    width: 25%;
    color: rgb(39, 39, 48);
}
#about{
    min-height:200px;
    border:1px solid #949494;
    background-color: #ffff;
    width: 100%;
    padding: 10px;
}

.login-P ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #4a4952;
    opacity: 1; /* Firefox */
}

.login-P ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #4a4952;
    opacity: 1; /* Firefox */
}

.infoColumn{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.photoColumn{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;

}

.logo{
    height:150px;
    width: auto;
    /* border-radius: 50%; */

}
#file{
    display: none;
}

.file{
    /* color: #ffff;
    background-color: #ffff!important;
    width:90px!important;
    display: none; */
}
.file-upload{
    margin: auto;
    display: flex;
    width: 100%!important;
    height:100%;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: #ffff!important;
    font-size: 10px!important;
    font-weight: bold;

}
.file-upload img{
    width:20px;
    height: auto;
    padding-right: 7px;
}
.label{
    width: 150px;
    height:35px;
    margin-top: 1rem;
    display: flex;
    background-color: #0b5dbd;
    justify-content: center;
    border-radius: 3px;
}
.label:hover{
    background-color:#1774df ;
}
.jobAdd{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width:450px;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    margin:50px;
    border:2px solid white;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    /* margin-top: 30px; */
}
.containerAdd{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    height:100%;
    padding: 30px;
}
.containerAdd textarea{
    background-color: #e1e4ea;
    /* border-color: #e1e4ea;
    border-style: solid; */
    border:none;
    width: 100%;
    min-height:200px;
    margin: 0;
    padding: 5px!important;
}
.containerAdd button{
    width:100%;
    background-color: #0b5dbd;
    color:white;
    height:40px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    font-size: 12px;
    font-weight: bold;
}

.row{
    padding-top:6px!important
}
.row input{
    padding:5px!important;
    height:35px!important
}




.test {
    width: 100vw;
    height: 100vh;
    color: #fff;
    background: linear-gradient(-45deg, #EE7752, #A5CC82, #1e82d4, #23D5AB);
    background-size: 400% 400%;
    -webkit-animation: Gradient 12s ease infinite;
    animation: Gradient 12s ease infinite;
    }
  
  /* .paused {
    -webkit-animation-play-state: paused; 
    animation-play-state: paused;
  } */
  
  @-webkit-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  

.forgot{
    height: 100vh;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    /* padding-top: 7vh; */
    background-color: #f8f8f8;
    display: flex;
    width: 100%;
    /* justify-content: center; */
    overflow-y: auto;
    flex-wrap: wrap;
    -webkit-animation: fadeInAnimation ease-in 0.6s;
            animation: fadeInAnimation ease-in 0.6s; 
    -webkit-animation-iteration-count: 1; 
            animation-iteration-count: 1; 
    -webkit-animation-fill-mode: forwards; 
            animation-fill-mode: forwards; 
}

.forgot-tab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items:flex-start; */
    height:300px;
    background-color: white;
    width:400px;
    /* border:2px solid white; */
    /* border-radius: 5px; */
    box-shadow: 5px 10px 15px #d8d8d8;
    margin: auto;
}
.forgot-tab .row{
  width:90%;
  margin:5px auto;
  justify-content: center!important;
}
.forgot-tab .button{
    margin-top: 0;
}

.forgot-tab .jobIcon-R{
    background-color: #0b5dbd;
    padding:5px
}
.forgot-header{
    color: rgb(55, 56, 58);
    font-weight: bold!important;
}
.forgot-tab form{
    width:100%;
    margin: 5px auto;
}
.forgot-tab h3{
   margin-right: auto;
}
