.test {
    width: 100vw;
    height: 100vh;
    color: #fff;
    background: linear-gradient(-45deg, #EE7752, #A5CC82, #1e82d4, #23D5AB);
    background-size: 400% 400%;
    -webkit-animation: Gradient 12s ease infinite;
    -moz-animation: Gradient 12s ease infinite;
    animation: Gradient 12s ease infinite;
    }
  
  /* .paused {
    -webkit-animation-play-state: paused; 
    animation-play-state: paused;
  } */
  
  @-webkit-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @-moz-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
