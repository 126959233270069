.forgot{
    height: 100vh;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    /* padding-top: 7vh; */
    background-color: #f8f8f8;
    display: flex;
    width: 100%;
    /* justify-content: center; */
    overflow-y: auto;
    flex-wrap: wrap;
    animation: fadeInAnimation ease-in 0.6s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
}

.forgot-tab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items:flex-start; */
    height:300px;
    background-color: white;
    width:400px;
    /* border:2px solid white; */
    /* border-radius: 5px; */
    box-shadow: 5px 10px 15px #d8d8d8;
    margin: auto;
}
.forgot-tab .row{
  width:90%;
  margin:5px auto;
  justify-content: center!important;
}
.forgot-tab .button{
    margin-top: 0;
}

.forgot-tab .jobIcon-R{
    background-color: #0b5dbd;
    padding:5px
}
.forgot-header{
    color: rgb(55, 56, 58);
    font-weight: bold!important;
}
.forgot-tab form{
    width:100%;
    margin: 5px auto;
}
.forgot-tab h3{
   margin-right: auto;
}