.mainContainer{
    display: inline-block;
    width: 100%;
    position: relative;
}
.profileList{
    margin-top:6vh;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;


    overflow-y: scroll;
}
.profileTab{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    height: 240px;
    width:150px;
    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
    margin:5px;
    /* padding:20px; */
    transition: transform .15s;

}
.profileTab:hover{
    border:1px solid rgb(129, 129, 141);
    /* background-color: #f6f6f6; */
    transform: scale(1.01); 
    /* transform: border 2px solid #919191 ease; */
 }
.profileTab h3{
  font-size: 1rem;
  margin-bottom: 5px;
  padding-left:5px!important;
}
.profileTab p{
    margin:5px!important;
    padding-left:3px!important;
}
.scrollContainer{
    width: 80%;
    height:100%;
    /* right:0; */
    left:23%;
    min-height: 90vh;

    position: fixed;
    /* float: right; */
    bottom: 0;
    top:0;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis; 

}
.profileView{
    width: 50%;
    right:0;
    min-height: 90vh;
    background-color: #ffff;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    /* float: right; */
    bottom: 0;
    top:0;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis; 
    border:2px solid #ebebeb

}

.profileView::-webkit-scrollbar{
    display: none;
}

.aboutJob{
    width: 20%;
    height: 100vh;
    background-color: #ffff;
    position: fixed;
    /* float: right; */
    bottom: 0;
    top:0;
    left: 0;
    /* word-wrap: break-word; */
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis; 
    border:2px solid #ebebeb;
    justify-content: center;
}
.jobContainer{
    width: 90%;
    height:90%;
    margin:auto;
}
.jobContainer p{
    overflow-wrap: break-word!important;
    padding:5px

}
.certPara{
    /* overflow-wrap: break-word;
    word-break: break-all; */

}
.apView{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:fit-content;
    height: fit-content;
    /* border:1px solid #0b5dbd; */
    background-color: rgb(240, 240, 240);
    margin-top:7vh;
    padding:20px
}
.title{
    font-size:13px;
    font-weight: 500;
}
.application{
    /* width:100%;
    background-color: #0b5dbd;
    color: #ffff;
    height:45px;
    padding: 0; */
    /* margin-left:5px; */
    /* border:none !important;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top:0;
    right:0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border:1px solid #8f8d9d; */
    /* margin-left: 0; */
}
.application img{
    height: 12px;
    width: auto;
    padding-left: 15px;
}
.application:hover{
    background-color: #0c6bd8;
}
.ll{
    position: relative;
    height: 100%;
    width:100%;
}
.rowButton{
    display: flex;
    flex-direction: row;
    width:fit-content;
    align-items: center;
    width:100%
}
.containerRows{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    background-color: white;
    /* margin:5px; */
}

.listRow{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width:100%
}
.row{
    display: flex;
    flex-direction: row;
    /* width:100%; */
    /* justify-content: center; */
    align-items: center;
}
.picContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding-left: 25px; */
    width:90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    background-color: #d3d3d3;
    margin-bottom: 5px;
    /* height:fit-content; */

}
.listHeading{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: fit-content;
    cursor: default;
}
.listHeading h3{
    text-align: left;
    width: 100%;
    font-size: .8rem;
    font-weight: 700;
    color: rgb(51, 54, 59);
}
.listHeading p{
    font-size: 12px;
    margin:5px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a0a0a0;
    opacity: 1; /* Firefox */
  }
#dp{
    /* width: auto!important;
    height: 80px!important; */
    /* border-radius: 50%; */
    /* object-fit: cover; */
    width: 100%;
    height: auto;
}
.profileTab .row{
    width:100%;
    padding: 2px 10px;
    margin:5px;
}
.profileTab .row img{
    width: 14px;
    height: auto;
    padding-left:5px!important;
}
.profileTab .row p{
    margin:1px!important;
    font-size: 0.7rem!important;
}
.certView{
    width:90%!important;
    height:auto;
    margin: auto;
}

.certHeading{
    font-size: 1rem;
    padding:5px;
    /* margin-top:35px; */
}
.jobTitle{
    margin-top:50px
}

.certContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: fit-content;
    margin:20px 5px 20px 5px;

    border:1px solid #eeeeee;
    box-shadow: 2px 3px 10px #d3d3d3;
    background-color: white;
}
.aboutContainer{
    width:100%;
    height: fit-content;
}
.jobRow{
    display: flex;
    flex-direction: row;
    width:100%;
    /* justify-content: center; */
    align-items: center;
    /* Renamed property in CSS3 draft spec */

}

.certImg{
    display: flex;
    justify-content: center;
}
.certInfo{
    /* display: flex;
    justify-content: center; */
}
.profileContainer{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    /* border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e0e0e0; */

    margin-left:auto;
    margin-right: auto;
    align-items: center;
    background-color: #f2f2f2;
}

.jobDetails::-webkit-scrollbar{
    display: none;
}

.viewApp{
    width:200px;
    background-color: #0b5dbd;
    color:white;
    height:35px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    font-size: 10px;
    font-weight: bold;
    position: sticky;
    right:0
}

.jobAbout{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top:7vh;
}
.jobAbout p{
    padding:5px
}
.jobAbout .listRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px ;
} 
.apView .row{
    background-color: rgb(240, 240, 240);
}
.apView .listRow{
    background-color: rgb(240, 240, 240);

}