@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    60%{
        opacity: 0.6;
    }
    100% { 
        opacity: 1; 
     } 
} 
.login-P{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:fit-content;
    padding:40px 0px;
    margin:40px 0px!important;
    background-color: white;
    width:600px;
    border:2px solid white;
    border-radius: 3px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: auto;
    animation: fadeInAnimation ease 0.8s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
}
.login-Pic{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:300px;
    padding:30px 0px;
    background-color: white;
    width:250px;
    border:2px solid white;
    border-radius: 3px;
    box-shadow: 5px 10px 15px #d3d3d3;
    margin: auto;
    animation: fadeInAnimation ease 0.8s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
}
.login-P form{
  width:90%
}

.row-input{
    display: flex;
    width: 100%;
    flex-direction: row;
}
.row-Error{
 width:100%;
}
.row h4{
    margin:10px 0px;
}
.button{
    height:40px;
    margin-top:25px;
    font-size: 0.8rem;
}
.row-error{
    display: flex;
    justify-content: flex-end;
}
.error{
    color: crimson;
    font-size: 0.65rem!important;
    margin-right: 5px;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.row .input-P{
    width:75%!important;
    padding-left:10px ;
    border:1px solid #e1e4ea;
    background-color: #e1e4ea;
}
.row label{
    font-size: 12px;
    width: 25%;
    color: rgb(39, 39, 48);
}
#about{
    min-height:200px;
    border:1px solid #949494;
    background-color: #ffff;
    width: 100%;
    padding: 10px;
}

.login-P ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #4a4952;
    opacity: 1; /* Firefox */
}

.infoColumn{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.photoColumn{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;

}

.logo{
    height:150px;
    width: auto;
    /* border-radius: 50%; */

}
#file{
    display: none;
}

.file{
    /* color: #ffff;
    background-color: #ffff!important;
    width:90px!important;
    display: none; */
}
.file-upload{
    margin: auto;
    display: flex;
    width: 100%!important;
    height:100%;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: #ffff!important;
    font-size: 10px!important;
    font-weight: bold;

}
.file-upload img{
    width:20px;
    height: auto;
    padding-right: 7px;
}
.label{
    width: 150px;
    height:35px;
    margin-top: 1rem;
    display: flex;
    background-color: #0b5dbd;
    justify-content: center;
    border-radius: 3px;
}
.label:hover{
    background-color:#1774df ;
}
.jobAdd{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width:450px;
    height:fit-content;
    margin:50px;
    border:2px solid white;
    border-radius: 5px;
    box-shadow: 5px 10px 15px #d3d3d3;
    /* margin-top: 30px; */
}
.containerAdd{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    height:100%;
    padding: 30px;
}
.containerAdd textarea{
    background-color: #e1e4ea;
    /* border-color: #e1e4ea;
    border-style: solid; */
    border:none;
    width: 100%;
    min-height:200px;
    margin: 0;
    padding: 5px!important;
}
.containerAdd button{
    width:100%;
    background-color: #0b5dbd;
    color:white;
    height:40px;
    padding: 0;
    margin: 0;
    border:none !important;
    margin-top: 15px;
    font-size: 12px;
    font-weight: bold;
}

.row{
    padding-top:6px!important
}
.row input{
    padding:5px!important;
    height:35px!important
}



